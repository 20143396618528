$(document).ready(function () {



    if ($(".loader-wrapper").length) {
        $('.loader-wrapper').delay(400).fadeOut("slow");
    }





    //$('.table').DataTable();

    //$(".table").wrap("<div class='table-responsive data-table-responsive'></div>");
    $(".edit-icon").click(function () {
        $("#edit-user-profile-section").addClass('admin-show');
        $("#user-profile-section").addClass('admin-hide');
    });
    $("#save-edit-user").click(function () {
        $("#edit-user-profile-section").removeClass('admin-show');
        $("#user-profile-section").removeClass('admin-hide');
    });
    $("#add-new-user-button").click(function () {
        $("#edit-user-profile-section").removeClass('admin-show');
        $("#user-profile-section").removeClass('admin-hide');

        $("#add-new-user-profile-section").addClass('admin-show');
        $("#user-profile-section").addClass('admin-hide');
    });
    $("#save-add-new-user").click(function () {
        $("#edit-user-profile-section").removeClass('admin-show');
        $("#add-new-user-profile-section").removeClass('admin-show');
        $("#user-profile-section").removeClass('admin-hide');
    });
    $("#add-new-user-customer-modal-btn").click(function () {
        $(".no-new-customer-statement").addClass('admin-hide');
        $("#add-new-user-customer-table").removeClass('admin-hide');

    });



    $(".display-item-list-button").click(function () {
        $("#private-list-section").addClass('admin-hide');
        $("#display-list-items-section").removeClass('admin-hide');

    });
    $("#back-to-private-label-button").click(function () {
        $("#private-list-section").removeClass('admin-hide');
        $("#display-list-items-section").addClass('admin-hide');

    });


    $("#exclusion-group-table .display-item-list-button").click(function () {
        $("#exclusion-group-list-items-section").removeClass('admin-hide');
        $("#exclusion-group-section").addClass('admin-hide');

    });
    $("#back-to-exclusion-group-button").click(function () {
        $("#exclusion-group-list-items-section").addClass('admin-hide');
        $("#exclusion-group-section").removeClass('admin-hide');

    });



    $("#exclusion-group-table .display-group-sold-to").click(function () {
        $("#exclusion-group-sold-to-list-section").removeClass('admin-hide');
        $("#exclusion-group-section").addClass('admin-hide');

    });
    $("#back-to-exclusion-group-sold-to-list-button").click(function () {
        $("#exclusion-group-sold-to-list-section").addClass('admin-hide');
        $("#exclusion-group-section").removeClass('admin-hide');

    });

}); // End of use ready